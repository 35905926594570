import React, {Component} from "react";
import {Link} from 'react-router-dom';
// import {loginAction} from '../../_actions';
// import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import Translate from "react-translate-component";
import counterpart from "counterpart";


import "./login.scss";


class Login extends Component {

    state = {
        user: {
            phoneNumber: '',
            password: '',
        },
        errors: {
            phoneNumberError: '',
            passwordError: '',
        }
    };

    setInput = (e) => {
        // const {name, value} = e.target;
        // const {user} = this.state;
        // const error = Validation.validateInputs(name,value)
        // this.setState({
        //     errors: {
        //         ...this.state.errors,
        //         [`${name}Error`]: error
        //     },
        //     user: {
        //         ...user,
        //         [name]: value
        //     }
        // });
    }

    login = (e) => {
        // e.preventDefault();
        // const {user, errors} = this.state;
        // if (!errors.phoneNumberError && !errors.passwordError && user.phoneNumber && user.password) {
        //     this.props.Login(user)
        // }
    }

    render() {
        const {
            user: {phoneNumber, password},
            errors: {phoneNumberError, passwordError},
        } = this.state;

        return (
            <div className="mainContent">
                {/*     {*/}
                {/*  isauthorized || UserExsiting  ?*/}
                {/*    <Redirect to='/' /> :*/}
                {/*    null*/}
                {/*}*/}
                <div className="container my-5">
                    <div className="row justify-content-center">
                        <div className="col-md-6 mb-5">
                            <div className=" m-2  shadow p-3 bg-white rounded LoginContent">
                                <h1 className="mainText text-center display-4"><Translate content="user.login"/></h1>
                                <form onSubmit={this.login}>
                                    <div className='form-group'>
                                        <div className='form-group'>
                                            <label><Translate content="user.email"/></label>
                                            <input type="password"
                                                   className='form-control'
                                                   name='password'
                                                   onChange={this.setInput}
                                                   value={password}
                                            />
                                            <div>
                                                <div className='form-group'>
                                                    <label><Translate content="user.password"/></label>
                                                    <input type="password"
                                                           className='form-control'
                                                           name='password'
                                                           onChange={this.setInput}
                                                           value={password}
                                                    />
                                                </div>
                                            </div>
                                            <div className='form-group'>
                                                <button type="submit" className="btn mainBtn w-100 text-center">
                                                    <Translate content="user.login"/></button>
                                            </div>
                                            <div className='form-group text-center'>
                                                <Link to={"/resetpassword"}
                                                      className="mainText">{counterpart.translate("user.resetPassword")}</Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    )
    }
    }

    // function mapStateToProps(state) {
//     const {isauthorized,loginError} = state.userReducer;
//     return {isauthorized,loginError};
// }
//
// const actionCreators = {
//     Login: loginAction,
// };
//
// const connectedLoginPage = connect(mapStateToProps, actionCreators)(Login);
// export {connectedLoginPage as Login};

        export default Login
