export * from './app';
export * from './layouts';
export * from './home';
export * from './news';
export * from './singleProduct';
export * from './newsDetals';
export * from './about';
export * from './contact';
export * from './banks';
export * from './profile';
// export * from './login';
// export * from './register';
// export * from './resetPassword';
