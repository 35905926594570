import React, {Component} from 'react';
import Translate from "react-translate-component";
import flag1 from './../../../_assets/img/flag1.png';
import flag from './../../../_assets/img/flag.svg';
import './footer.scss';

export class Footer extends Component {
    render() {
        return (
            <footer className="footerContetn py-3">
                <div className="promo" style={{textAligen: "center"}}>
                    {/*<img src={flag} alt="armenia"/>*/}
                    {/* <marquee scrollamount="5"> */}
                    Հայտի Դիտարկումը 15 րոպեում։
                    {/* </marquee> */}
                    {/*<img src={flag1} alt="armenia"/>*/}
                </div>
                <section className="container">
                    <div className="row">
                        <div className="col-md-12 text-center footerContetn--copyright">
                            <Translate content="footer.copyright"/>
                        </div>
                    </div>
                </section>
            </footer>)
    }
}
