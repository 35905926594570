import { newsConstants } from './../../_constants';
import {selectNews,singleNews} from "../../_services";

export const newsAction =(type)=> {
    return function (dispatch) {
        selectNews(type).then(
            (response) => dispatch({
                type: newsConstants.NEWS_SUCCESS,
                data: response
            })
        ).catch((error) => dispatch({
            type: newsConstants.NEWS_FAILURE,
            error: error && error.response && error.response.status
        }))
    }
}

export const singleNewsAction =(id)=> {
    return function (dispatch) {
        singleNews(id).then(
            (response) => dispatch({
                type: newsConstants.SINGLE_NEWS_SUCCESS,
                data: response
            })
        ).catch((error) => dispatch({
            type: newsConstants.SINGLE_NEWS_FAILURE,
            error: error && error.response && error.response.status
        }))
    }
}
