import { mailConstants } from './../../_constants';
import {sendMAil} from "../../_services";

export const mailAction =(body)=> {
    return function (dispatch) {
        sendMAil(body).then(
            (response) => dispatch({
                type: mailConstants.MAIL_SUCCESS,
                data: response
            })
        ).catch((error) => dispatch({
            type: mailConstants.MAIL_FAILURE,
            error: error && error.response && error.response.status
        }))
    }
}
