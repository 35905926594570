import React,{Component} from 'react';
import {Router,Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import {history, store} from "./../../_helpers";
import {Header,Home,Footer,SingleProduct,About,Contact,NewsDetals,BanksInfo,Banknews,Profile
    // Login,Register,ResetPassword
} from './../index';

import {News} from './../news';
import Login from './../login/login';
import Register from './../register/register';
import ResetPassword from './../resetPassword/resetPassword';


import counterpart from 'counterpart';
import ru_common from './../../_translations/ru/common.json';
import './app.scss';
counterpart.registerTranslations('ru',ru_common);
counterpart.setLocale('ru');


export class App extends Component {
    render () {
        return (
            <Provider store={store}>
                <Router history={history}>
                    <div className="app-container">
                        <Header />
                        <div className="app-container--content">
                          <Switch>
                              <Route exact path="/" component={Home} />
                              <Route exact path="/news" component={News} />
                              <Route exact path="/about" component={About} />
                              <Route exact path="/banks/information" component={BanksInfo} />
                              <Route exact path="/banks/news" component={Banknews} />
                              {/*<Route exact path="/contact" component={Contact} />*/}
                              {/*<Route exact path="/login" component={Login} />*/}
                              {/*<Route exact path="/Profile/:id" component={Profile} />*/}
                              {/*<Route exact path="/register" component={Register} />*/}
                              {/*<Route exact path="/resetpassword" component={ResetPassword} />*/}
                              <Route path="/news/details/:id" component={NewsDetals} />
                          </Switch>
                          </div>
                        <Footer/>
                    </div>
                </Router>
            </Provider>)
    }
}
