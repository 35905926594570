import React from 'react';
import counterpart from "counterpart";
import './steps.scss';


export const Steps = ({step}) => {
    return (
        <ul className="progress">
            <li className={`${step===0 ? "is-active" : step===1 || step===2 ||  step===3 ?"is-complete":""}`} data-step="1">
                {counterpart.translate("step")} 1
            </li>
            <li className={`${step===1 ? "is-active" : step===2 ||  step===3 ?"is-complete":"" }`} data-step="2">
                {counterpart.translate("step")} 2
            </li>
            <li className={`${step===2 ? "is-active" : step===3 ?"is-complete":""}`} data-step="3" >
                {counterpart.translate("step")} 3
            </li>
        </ul>
    );
           
}
