import React, { Component } from "react";
import Translate from "react-translate-component";
import { FiShoppingCart } from 'react-icons/fi';
import { FaHeart } from 'react-icons/fa';



import './singleproduct.scss';

export class SingleProduct extends Component {
    render() {
        return (
            <div className="ProductContent">
                <section className="container slideContent my-5">
                    <div className="row">
                        <div className="col-md-6">
                        </div>
                        <div className="col-md-6 ProductContent--infoBox">
                            <h1 className="ProductContent--infoBox--title">Название продукта</h1>
                            <div className="ProductContent--infoBox--description my-2">
                                описание этого продукта описание этого продукта описание этого продукта
                                описание этого продукта описание этого продукта описание этого продукта
                            </div>
                            <ul className="ProductContent--infoBox--details">
                                <li className="ProductContent--infoBox--details--item">
                                    <p  className="ProductContent--infoBox--details--item--title">
                                        <Translate content="product.category"/>
                                    </p>
                                    <p  className="ProductContent--infoBox--details--item--description">
                                        ОЖЕРЕЛЬЕ
                                    </p>
                                </li>
                                <li className="ProductContent--infoBox--details--item">
                                    <p className="ProductContent--infoBox--details--item--title">
                                        <Translate content="product.quantity"/>
                                    </p>
                                    <div className="ProductContent--infoBox--details--item--description">
                                        <select>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                            <option>6</option>
                                            <option>7</option>
                                            <option>8</option>
                                            <option>9</option>
                                            <option>10</option>
                                        </select>
                                    </div>
                                </li>
                            </ul>
                            <div className="ProductContent--infoBox--buttons my-2">
                                <button className="ProductContent--infoBox--buttons--btn">
                                    <FiShoppingCart/> <Translate content="product.add to cart"/>
                                </button>
                                <button className="ProductContent--infoBox--buttons--btn">
                                    <FaHeart/> <Translate content="product.add to wishlist"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
