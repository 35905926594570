import React, { Component } from "react";
import vtb from "../../_assets/img/banks/vtb.svg";
import ardshinbank from "../../_assets/img/banks/ardshinbank.jpg"
import ameria from "../../_assets/img/banks/ameria.png"
import akba from "../../_assets/img/banks/akba.jpg"
import uni from "../../_assets/img/banks/uni.jpg"
import ev from "../../_assets/img/banks/ev.png"
import ineco from "../../_assets/img/banks/ineco.png"
import abb from "../../_assets/img/banks/abb.png"
import hayekonom from "../../_assets/img/banks/hayekonom.png"
import HSBC from "../../_assets/img/banks/HSBC.png"
import convers from "../../_assets/img/banks/convers.jpg"
import ararat from "../../_assets/img/banks/ararat.jpg"
import mellat from "../../_assets/img/banks/mellat.jpg"
import arcaxbank from "../../_assets/img/banks/arcaxbank.png"
import biblos from "../../_assets/img/banks/biblos.jpg"

import {FiPhone} from 'react-icons/fi';
import './banks.scss';



export class BanksInfo extends Component {
    render() {
        return (
            <div className="newsContent">
                <section className="container  my-5">
                   <div className="row">
                       <div className="col-md-12">
                           <div className="media my-3">
                               <img src={vtb} className="mr-3 img-fluid banksLogo" alt="aaa" />
                               <div className="media-body">
                                   <h5 className="mt-0">ՎՏԲ</h5>
                                   <ul className="list-group list-group-flush">
                                       <li className="list-group-item"><FiPhone />8787</li>
                                       {/*<li className="list-group-item"><FiMail/> ameria@ameria</li>*/}
                                   </ul>
                               </div>
                           </div>
                           <div className="media my-3">
                               <img src={ardshinbank} className="mr-3 img-fluid banksLogo" alt="aaa" />
                               <div className="media-body">
                                   <h5 className="mt-0">Արդշին բանկ</h5>
                                   <ul className="list-group list-group-flush">
                                       <li className="list-group-item"><FiPhone />012 22 22 22</li>
                                       {/*<li className="list-group-item"><FiMail/> ameria@ameria</li>*/}
                                   </ul>
                               </div>
                           </div>
                           <div className="media my-3">
                               <img src={ameria} className="mr-3 img-fluid banksLogo" alt="aaa" />
                               <div className="media-body">
                                   <h5 className="mt-0">Ամերիաբանկ</h5>
                                   <ul className="list-group list-group-flush">
                                       <li className="list-group-item"><FiPhone />010 56 11 11</li>
                                       {/*<li className="list-group-item"><FiMail/> ameria@ameria</li>*/}
                                   </ul>
                               </div>
                           </div>
                           <div className="media my-3">
                               <img src={akba} className="mr-3 img-fluid banksLogo" alt="aaa" />
                               <div className="media-body">
                                   <h5 className="mt-0">ԱԿԲԱ Բանկ</h5>
                                   <ul className="list-group list-group-flush">
                                       <li className="list-group-item"><FiPhone />010 31 88 88</li>
                                       {/*<li className="list-group-item"><FiMail/> ameria@ameria</li>*/}
                                   </ul>
                               </div>
                           </div>
                           <div className="media my-3">
                               <img src={uni} className="mr-3 img-fluid banksLogo" alt="aaa" />
                               <div className="media-body">
                                   <h5 className="mt-0">Յունիբանկ</h5>
                                   <ul className="list-group list-group-flush">
                                       <li className="list-group-item"><FiPhone />010 59 55 55</li>
                                       {/*<li className="list-group-item"><FiMail/> ameria@ameria</li>*/}
                                   </ul>
                               </div>
                           </div>
                           <div className="media my-3">
                               <img src={ev} className="mr-3 img-fluid banksLogo" alt="aaa" />
                               <div className="media-body">
                                   <h5 className="mt-0">Էվոկաբանկ</h5>
                                   <ul className="list-group list-group-flush">
                                       <li className="list-group-item"><FiPhone />010 60 55 55</li>
                                       {/*<li className="list-group-item"><FiMail/> ameria@ameria</li>*/}
                                   </ul>
                               </div>
                           </div>
                           <div className="media my-3">
                               <img src={ineco} className="mr-3 img-fluid banksLogo" alt="aaa" />
                               <div className="media-body">
                                   <h5 className="mt-0">Ինեկոբանկ</h5>
                                   <ul className="list-group list-group-flush">
                                       <li className="list-group-item"><FiPhone />010 51 05 10</li>
                                       {/*<li className="list-group-item"><FiMail/> ameria@ameria</li>*/}
                                   </ul>
                               </div>
                           </div>
                           <div className="media my-3">
                               <img src={abb} className="mr-3 img-fluid banksLogo" alt="aaa" />
                               <div className="media-body">
                                   <h5 className="mt-0">Հայբիզնեսբանկ</h5>
                                   <ul className="list-group list-group-flush">
                                       <li className="list-group-item"><FiPhone />010 59 20 20</li>
                                       {/*<li className="list-group-item"><FiMail/> ameria@ameria</li>*/}
                                   </ul>
                               </div>
                           </div>
                           <div className="media my-3">
                               <img src={hayekonom} className="mr-3 img-fluid banksLogo" alt="aaa" />
                               <div className="media-body">
                                   <h5 className="mt-0">Հայէկոնոմբանկ</h5>
                                   <ul className="list-group list-group-flush">
                                       <li className="list-group-item"><FiPhone />010 51 09 10</li>
                                       {/*<li className="list-group-item"><FiMail/> ameria@ameria</li>*/}
                                   </ul>
                               </div>
                           </div>
                           <div className="media my-3">
                               <img src={HSBC} className="mr-3 img-fluid banksLogo" alt="aaa" />
                               <div className="media-body">
                                   <h5 className="mt-0">HSBC</h5>
                                   <ul className="list-group list-group-flush">
                                       <li className="list-group-item"><FiPhone />060 65 50 00</li>
                                       {/*<li className="list-group-item"><FiMail/> ameria@ameria</li>*/}
                                   </ul>
                               </div>
                           </div>
                           <div className="media my-3">
                               <img src={convers} className="mr-3 img-fluid banksLogo" alt="aaa" />
                               <div className="media-body">
                                   <h5 className="mt-0">Կոնվերսբանկ</h5>
                                   <ul className="list-group list-group-flush">
                                       <li className="list-group-item"><FiPhone /> 010 51 12 11</li>
                                       {/*<li className="list-group-item"><FiMail/> ameria@ameria</li>*/}
                                   </ul>
                               </div>
                           </div>
                           <div className="media my-3">
                               <img src={ararat} className="mr-3 img-fluid banksLogo" alt="aaa" />
                               <div className="media-body">
                                   <h5 className="mt-0">Արարատբանկ</h5>
                                   <ul className="list-group list-group-flush">
                                       <li className="list-group-item"><FiPhone />010 59 23 23</li>
                                       {/*<li className="list-group-item"><FiMail/> ameria@ameria</li>*/}
                                   </ul>
                               </div>
                           </div>
                           <div className="media my-3">
                               <img src={mellat} className="mr-3 img-fluid banksLogo" alt="aaa" />
                               <div className="media-body">
                                   <h5 className="mt-0">Մելլաթբանկ</h5>
                                   <ul className="list-group list-group-flush">
                                       <li className="list-group-item"><FiPhone />060 38 88 88</li>
                                       {/*<li className="list-group-item"><FiMail/> ameria@ameria</li>*/}
                                   </ul>
                               </div>
                           </div>
                           <div className="media my-3">
                               <img src={arcaxbank} className="mr-3 img-fluid banksLogo" alt="aaa" />
                               <div className="media-body">
                                   <h5 className="mt-0">Արցախբանկ</h5>
                                   <ul className="list-group list-group-flush">
                                       <li className="list-group-item"><FiPhone />012 32 30 01</li>
                                       {/*<li className="list-group-item"><FiMail/> ameria@ameria</li>*/}
                                   </ul>
                               </div>
                           </div>
                           <div className="media my-3">
                               <img src={biblos} className="mr-3 img-fluid banksLogo" alt="aaa" />
                               <div className="media-body">
                                   <h5 className="mt-0">Բիբլոսբանկ</h5>
                                   <ul className="list-group list-group-flush">
                                       <li className="list-group-item"><FiPhone />010 53 03 62</li>
                                       {/*<li className="list-group-item"><FiMail/> ameria@ameria</li>*/}
                                   </ul>
                               </div>
                           </div>
                       </div>
                   </div>
                </section>
            </div>
        )
    }
}
