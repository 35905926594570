import React, {Component} from "react";
import {Link} from 'react-router-dom';
import Translate from "react-translate-component";
import counterpart from "counterpart";
import {GiReceiveMoney, GiMoneyStack} from 'react-icons/gi';
import {IoMdHelpBuoy} from 'react-icons/io';
import {Tabs, Tab, Row, Col, Nav, Modal, Button} from "react-bootstrap";
import {RiInformationLine} from 'react-icons/ri';
import {FaRegHandshake, FaMapMarkerAlt, FaPassport, FaIdCard} from 'react-icons/fa';
import {BsCalendar, BsPhone} from 'react-icons/bs';
import {AiOutlineUser, AiOutlinePercentage} from 'react-icons/ai';
import InputRange from 'react-input-range';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css'
import "react-input-range/lib/css/index.css";
import {mailAction, newsAction} from "../../_actions";
import {connect} from "react-redux";
import {BASE_URL} from "../../_config";
import {VerticallyCenteredModal} from './../../_components';
import TooltipSlider from './../../_components/sliderTooltip';


class Home extends Component {
    state = {
        body: {
            "name": "",
            "address": "",
            "telephone": "",
            "birthday_date": "",
            "history_type": "",
            "registered": "",
            "salary": 0,
            "amount_in_need": 100000,
            "month": 1,
            "passport_serial_number": "",
            "social_serial_number": "",
            "request_answer_time": ""
        },
        modalShow: false,
        calculatior: false
    }



    calculate(chart) {
        var amount = document.getElementById("amount");
        var apr = document.getElementById("apr");
        var years = document.getElementById("years");
        var payment = document.getElementById("payment");
        var total = document.getElementById("total");
        var totalinterest = document.getElementById("totalinterest");
        
      // Get the user's input from the input elements.
      // Convert interest from a percentage to a decimal, and convert from
      // an annual rate to a monthly rate. Convert payment period in years
      // to the number of monthly payments.
      var principal = parseFloat(amount.value);
      var interest = parseFloat(apr.value) / 100 / 12;
      var payments = parseFloat(years.value) ;
        
      // compute the monthly payment figure
      var x = Math.pow(1 + interest, payments); //Math.pow computes powers
      var monthly = (principal*x*interest)/(x-1);
      
      // If the result is a finite number, the user's input was good and
      // we have meaningful results to display
      if (isFinite(monthly)){
        // Fill in the output fields, rounding to 2 decimal places
        payment.innerHTML = monthly.toFixed(2);
        total.innerHTML = (monthly * payments).toFixed(2);
        totalinterest.innerHTML = ((monthly*payments)-principal).toFixed(2);
        

       chart(principal, interest, monthly, payments);
      } else {
       // Result was Not-a-Number or infinite, which means the input was
       // incomplete or invalid. Clear any previously displayed output.
       payment.innerHTML = ""; // Erase the content of these elements
       total.innerHTML = ""
       totalinterest.innerHTML = "";
       chart(); // With no arguments, clears the chart
       }
      }
   
      chart(principal, interest, monthly, payments) {
       var graph = document.getElementById("graph");
       if (arguments.length == 0 || !graph.getContext) return;
    
       var g = graph.getContext("2d"); 
       graph.style.width='100%';
       var width = graph.width, height = graph.height; 
       function paymentToX(n) { return n * width/payments; }
       function amountToY(a) { return height-(a * height/(monthly*payments*1.05));}
    
       g.moveTo(paymentToX(0), amountToY(0)); 
       g.lineTo(paymentToX(payments),
       amountToY(monthly*payments));
        
        g.lineTo(paymentToX(payments), amountToY(0)); 
       g.closePath();
       g.fillStyle = "#f88"; // Light red
       g.fill(); // Fill the triangle
       g.font = "bold 12px sans-serif"; // Define a font
       g.fillText("Ընդհանուր մուծում", 20,20); // Draw text in legend
       // Cumulative equity is non-linear and trickier to chart
       var equity = 0;
       g.beginPath(); // Begin a new shape
       g.moveTo(paymentToX(0), amountToY(0)); // starting at lower-left
       for(var p = 1; p <= payments; p++) {
       // For each payment, figure out how much is interest
       var thisMonthsInterest = (principal-equity)*interest;
       equity += (monthly - thisMonthsInterest); // The rest goes to equity
       g.lineTo(paymentToX(p),amountToY(equity)); // Line to this point
       }
       g.lineTo(paymentToX(payments), amountToY(0)); // Line back to X axis
       g.closePath(); // And back to start point
       g.fillStyle = "green"; // Now use green paint
       g.fill(); // And fill area under curve
    //    g.fillText("ամսեկան մուծում", 20,35); // Label it in green
       // Loop again, as above, but chart loan balance as a thick black line
       var bal = principal;
       g.beginPath();
       g.moveTo(paymentToX(0),amountToY(bal));
       for(var p = 1; p <= payments; p++) {
       var thisMonthsInterest = bal*interest;
       bal -= (monthly - thisMonthsInterest); // The rest goes to equity
       g.lineTo(paymentToX(p),amountToY(bal)); // Draw line to this point
       }
       g.lineWidth = 3; // Use a thick line
       g.stroke(); // Draw the balance curve
       g.fillStyle = "black"; // Switch to black text
       g.fillText("Վարկի չափը", 20,50); // Legend entry
       // Now make yearly tick marks and year numbers on X axis
       g.textAlign="center"; // Center text over ticks
       var y = amountToY(0); // Y coordinate of X axis
       for(var year=1; year*12 <= payments; year++) { // For each year
       var x = paymentToX(year*12); // Compute tick position
       g.fillRect(x-0.5,y-3,1,3); // Draw the tick
       if (year == 1) g.fillText("Ամիս", x, y-5); // Label the axis
       if (year % 5 == 0 && year*12 !== payments) // Number every 5 years
       g.fillText(String(year), x, y-5);
       }
       // Mark payment amounts along the right edge
       g.textAlign = "right"; // Right-justify text
       g.textBaseline = "middle"; // Center it vertically
       var ticks = [monthly*payments, principal]; // The two points we'll mark
       var rightEdge = paymentToX(payments); // X coordinate of Y axis
       for(var i = 0; i < ticks.length; i++) { // For each of the 2 points
       var y = amountToY(ticks[i]); // Compute Y position of tick
      
         g.fillRect(rightEdge-3, y-0.5, 3,1); // Draw the tick mark
       g.fillText(String(ticks[i].toFixed(0)), // And label it.
       rightEdge-5, y);
       }
      } 







    handleClose = () => this.setState({calculatior: false});
    handleShow = () =>  this.setState({calculatior: true});

    componentDidMount() {
        this.props.news("another")
    }

    changeBody = (e) => {
        const {name, value} = e.target;
        this.setState(prevState => ({
            body: {
                ...prevState.body,
                [name]: value
            }
        }))
    }
    handleMail = (e) => {
       
        e.preventDefault()
        const {body} = this.state;
        this.setModalShow(true)
        this.props.mail(body)
    }
    setModalShow = (show) => {
        this.setState({modalShow: show})
    }

    render() {
        const {body, modalShow} = this.state;
        const {newsData} = this.props;
        return (
            <div className="homeContent">
                <div className="container homeContent--form">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="homeContent--form--header">
                                Ucash.am Ավելի քան 5 տարի շուկայում։
                            </div>
                        </div>
                        <div className="col-md-12 textBanner">
                            <h1 className="headline">
                                <div id="wordgroup1" className="rotate animation-1">
                                <span className="">11%</span>
                                    <span className="">2.000.000 դրամ</span>
                                </div>
                                <span className="divider"></span>
                                <div className="rotate animation-2">
                                <span>տոկոսադրույք</span>
                                    <span>25 րոպեում</span>
                                </div>
                            </h1>
                        </div>
                        <div className="col-md-12">
                            <Tabs defaultActiveKey="info" className=" navbar_home" id="uncontrolled-tab-example">
                                <Tab eventKey="info" title={counterpart.translate("Application.title")}>
                                    <form className="mb-5" onSubmit={(e) => this.handleMail(e)}>
                                        <div className="input-group input-group-icon">
                                            <input type="text"
                                                   placeholder={counterpart.translate("user.firstName") + " " + counterpart.translate("user.lastName")}
                                                   name="name"
                                                   value={body.name}
                                                   onChange={(e) => this.changeBody(e)}/>
                                            <div className="input-icon"><AiOutlineUser/></div>
                                        </div>
                                        <div className="input-group input-group-icon">
                                            <input type="text" placeholder={counterpart.translate("user.address")}
                                                   name="address"
                                                   value={body.address}
                                                   onChange={(e) => this.changeBody(e)}/>
                                            <div className="input-icon"><FaMapMarkerAlt/></div>
                                        </div>
                                        <div className="input-group input-group-icon">
                                            <input type="text" placeholder={counterpart.translate("user.phone")}
                                                   name="telephone"
                                                   value={body.telephone}
                                                   onChange={(e) => this.changeBody(e)}/>
                                            <div className="input-icon"><BsPhone/></div>
                                        </div>
                                        <div className="input-group input-group-icon">
                                            <input type="text" placeholder={counterpart.translate("user.birthday")}
                                                   name="birthday_date"
                                                   value={body.birthday_date}
                                                   onChange={(e) => this.changeBody(e)}/>
                                            <div className="input-icon"><BsCalendar/></div>
                                        </div>
                                        <h4>{counterpart.translate("Application.history")}</h4>
                                        <div className="input-group">
                                            <input id="plus" type="radio"
                                                   name="history_type"
                                                   value={counterpart.translate("user.plus")}
                                                   onChange={(e) => this.changeBody(e)}/>
                                            <label htmlFor="plus">{counterpart.translate("user.plus")}</label>
                                        </div>
                                        <div className="input-group">
                                            <input id="minus" type="radio"
                                                   name="history_type"
                                                   value={counterpart.translate("user.minus")}
                                                   onChange={(e) => this.changeBody(e)}/>
                                            <label htmlFor="minus">{counterpart.translate("user.minus")}</label>
                                        </div>
                                        <div className="input-group">
                                            <input id="havenot" type="radio"
                                                   name="history_type"
                                                   value={counterpart.translate("user.havenot")}
                                                   onChange={(e) => this.changeBody(e)}/>
                                            <label htmlFor="havenot">{counterpart.translate("user.havenot")}</label>
                                        </div>
                                        <h4>{counterpart.translate("Application.work")}</h4>
                                        <div className="input-group">
                                            <input id="yes" type="radio"
                                                   name="registered"
                                                   value={counterpart.translate("user.yes")}
                                                   onChange={(e) => this.changeBody(e)}/>
                                            <label htmlFor="yes">{counterpart.translate("user.yes")}</label>
                                        </div>
                                        <div className="input-group">
                                            <input id="no" type="radio"
                                                   name="registered"
                                                   value={counterpart.translate("user.no")}
                                                   onChange={(e) => this.changeBody(e)}/>
                                            <label htmlFor="no">{counterpart.translate("user.no")}</label>
                                        </div>
                                        <div className="input-group input-group-icon">
                                            <input type="number"
                                                   placeholder={counterpart.translate("Application.money")}
                                                   name="salary"
                                                   value={body.salary}
                                                   onChange={(e) => this.changeBody(e)}/>
                                            <div className="input-icon"><GiMoneyStack/></div>
                                        </div>
                                        <h4>{counterpart.translate("Application.money1")}</h4>
                                        <div className="input-group my-5 inputRange">
                                        <TooltipSlider
                                             tipFormatter={(val) => `${val} Դրամ`}
                                            tipProps={{ overlayClassName: 'foo' }}
                                        
                                            defaultValue={[250000, 2000000]}
                                            max={2000000}
                                            min={250000}
                                            step={1000}
                                            onChange={money =>
                                                this.setState(prevState => ({
                                                    body: {
                                                        ...prevState.body,
                                                        "amount_in_need": money
                                                    }
                                                }))}
      />
                                          <div style={{
                                            display: 'flex',
                                             alignItems: 'center',
                                             justifyContent: 'space-between',
                                             width: '100%',
                                             marginTop: '10px',
                                             fontSize: '12px'}}><p>250000 Դրամ</p><p>2000000 Դրամ</p></div>
                                            
                                        </div>
                                        <h4>{counterpart.translate("Application.mount")}</h4>
                                        <div className="input-group my-5 inputRange">
                                        <TooltipSlider
                                             tipFormatter={(val) => `${val} Ամիս`}
                                            tipProps={{ overlayClassName: 'foo' }}
                                            defaultValue={[1, 36]}
                                            max={36}
                                            min={1}
                                            step={1}
                                            onChange={month =>
                                                this.setState(prevState => ({
                                                    body: {
                                                        ...prevState.body,
                                                        "month": month
                                                    }
                                                }))
                                            }
      />
                                          <div style={{
                                            display: 'flex',
                                             alignItems: 'center',
                                             justifyContent: 'space-between',
                                             width: '100%',
                                             marginTop: '10px',
                                             fontSize: '12px'}}><p>1 Ամիս</p><p>36 Ամիս</p></div>
                                            
                                        </div>
                                        {/*<div className="input-group input-group-icon">*/}
                                        {/*    <input type="text" placeholder={counterpart.translate("Application.pasport")}*/}
                                        {/*           name="passport_serial_number"*/}
                                        {/*           value={body.passport_serial_number}*/}
                                        {/*           onChange={(e)=>this.changeBody(e)}    />*/}
                                        {/*    <div className="input-icon"><FaPassport/></div>*/}
                                        {/*</div>*/}
                                        {/*<div className="input-group input-group-icon">*/}
                                        {/*    <input type="text" placeholder={counterpart.translate("Application.userCard")}*/}
                                        {/*           name="social_serial_number"*/}
                                        {/*           value={body.social_serial_number}*/}
                                        {/*           onChange={(e)=>this.changeBody(e)}    />*/}
                                        {/*    <div className="input-icon"><FaIdCard/></div>*/}
                                        {/*</div>*/}
                                        <h4>{counterpart.translate("Application.time.title")}</h4>
                                        <div className="input-group">
                                            <input id="request_answer_time" type="radio"
                                                   name="request_answer_time"
                                                   value={counterpart.translate("Application.time.1")}
                                                   onChange={(e) => this.changeBody(e)}/>
                                            <label
                                                htmlFor="request_answer_time">{counterpart.translate("Application.time.1")}</label>
                                        </div>
                                        <div className="input-group">
                                            <input id="request_answer_time2" type="radio"
                                                   name="request_answer_time"
                                                   value={counterpart.translate("Application.time.2")}
                                                   onChange={(e) => this.changeBody(e)}/>
                                            <label
                                                htmlFor="request_answer_time2">{counterpart.translate("Application.time.2")}</label>
                                        </div>
                                        <div className="input-group">
                                            <input id="request_answer_time3" type="radio"
                                                   name="request_answer_time"
                                                   value={counterpart.translate("Application.time.3")}
                                                   onChange={(e) => this.changeBody(e)}/>
                                            <label
                                                htmlFor="request_answer_time3">{counterpart.translate("Application.time.3")}</label>
                                        </div>
                                        <div className="input-group my-4  justify-content-center">
                                            <button className="btn  mainBtn"><Translate content="user.submit"/></button>
                                        </div>
                                    </form>
                                </Tab>
                                <Tab eventKey="send" title={counterpart.translate("home.condition")}>
                                    <div>
                                        <p>Անկախ վարկի չափից՝ ամսավճարի մոտ 80% գումարը մարվում է մայր գումարից։</p>
                                        <p> Գումարը տրամադրվում է հետևյալ պայմաններով.</p>
                                        <p style={{textDecoration: "underline", textDecorationColor: "#009846"}}>Տարեկան տոկոսադրույք 11%</p>
                                        <p style={{textDecoration: "underline", textDecorationColor: "#009846"}}>Սպասարկման վճար 0.6-0.99% </p>
                                        <p style={{textDecoration: "underline", textDecorationColor: "#009846"}}>Տարի մարման ժամկետ 3-4</p>

                                        <p>Գրասենյակը գտնվում է Բաղրամյան 56 հասցեում։</p>

                            
                                        <p>Վարկի նվազագույն գումար՝ 250.000 դրամ, առավելագույնը 2.000.000 դրամ</p>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                        <div className="col-md-12">
                            <button className="btn btn-primary w-100" onClick={this.handleShow}>Վարկային Հաշվիչ</button>
                        </div>
                    </div>

                </div>
                <section className="container topProductsContent">
                <div class="row">
                    <div className="col-md-12">
                        <h1 className="title_1 my-3">Օգտակար հղումներ</h1>
                    </div>
                    <div className="col-md-12 py-5">
                        <p>Վստահելի են այն կազմակերպությունները, որոնց սոցիալական ցանցերի բիզնես էջերն ունեն մեծ թվով հետևորդներ, կամ այն հարթակը, որտեղ առաջարկվում է լրացնել վարկային հայտ, ունի ներկայանալի և լուրջ տեսք։</p>
                        <p>Խորհուրդ է տրվում նախքան որևէ վարկային առաջարկին արձագանքելը ուսումնասիրել տվյալ հարթակը խաբեության զոհ չդառնալու համար։</p>
                    </div>
                </div>
                    
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="title_1 my-3"><Translate content="services.title"/></h1>
                        </div>
                    </div>
                    <div className="row d-flex align-items-stretch mt-5 mb-3">
                        <div className="col-md-4 services">
                            <div className="services--box text-center shadow-sm p-3 mb-5 bg-white rounded">
                                <div className="services--box--iconBox">
                                    <RiInformationLine/>
                                </div>
                                <div className="services--box--title"><Translate content="services.bage1.title"/></div>
                                <div className="services--box--description"><Translate
                                    content="services.bage1.description"/></div>
                            </div>
                        </div>
                        <div className="col-md-4 services">
                            <div className="services--box text-center shadow-sm p-3 mb-5 bg-white rounded">
                                <div className="services--box--iconBox">
                                    <IoMdHelpBuoy/>
                                </div>
                                <div className="services--box--title"><Translate content="services.bage2.title"/></div>
                                <div className="services--box--description"><Translate
                                    content="services.bage2.description"/></div>
                            </div>
                        </div>
                        <div className="col-md-4 services">
                            <div className="services--box text-center shadow-sm p-3 mb-5 bg-white rounded">
                                <div className="services--box--iconBox">
                                    <GiReceiveMoney/>
                                </div>
                                <div className="services--box--title"><Translate content="services.bage3.title"/></div>
                                <div className="services--box--description"><Translate
                                    content="services.bage3.description"/></div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="container topProductsContent">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="title_1 my-3"><Translate content="news.title"/></h1>
                        </div>
                    </div>
                    <div className="row my-5">
                        {newsData && newsData.length ? newsData.map((news, index) => {
                                if (index < 5) {
                                    return <div className="col-md-6">
                                        <div className="card mb-3 p-0 ">
                                            <div className="row no-gutters">
                                                <div className="col-md-4">
                                                    <img src={`${BASE_URL}${news.image}`} className="card-img h-100"
                                                         alt="..."/>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="card-body">
                                                        <h5 className="card-title">{news.title}</h5>
                                                        <p className="card-text">{news.description}</p>
                                                        <p className="card-text"><BsCalendar className="mr-2"/><small
                                                            className="text-muted">{news.created_at}</small></p>
                                                        <Link to={`news/details/${news.id}`} type="button"
                                                              className="btn btn-secondary"><Translate content="news.more"/></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            }
                        ) : ""}
                    </div>
                    <div className="row justify-content-center mb-5">
                        <Link to={"news"} type="button" className="btn  mainBtn"><Translate content="news.all"/></Link>
                    </div>
                </section>
                <VerticallyCenteredModal
                    show={modalShow}
                    onHide={() => this.setModalShow(false)}
                />
                <Modal show={this.state.calculatior} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Վարկային Հաշվիչ</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="conatiner  homeContent--form">
                        <form className="mb-5" >
                            <div className="input-group input-group-icon">
                                <input type="text" id="amount" placeholder="Գումարի չափը"/>
                                <div className="input-icon"><GiMoneyStack/></div>
                            </div>
                            <div className="input-group input-group-icon">
                                <input type="text" id="apr" placeholder="Տոկոսադրույք"/>
                                <div className="input-icon"><AiOutlinePercentage/></div>
                            </div>
                            <div className="input-group input-group-icon">
                                <input type="text" id="years" placeholder="Ամիսների քանակը"/>
                                <div className="input-icon"><BsCalendar/></div>
                            </div>
                            <Button variant="success" onClick={() => this.calculate(this.chart)}>Հաշվարկել</Button>
                        </form>
                        <table>
                            <tr><td>Ամսեկան Վճար:</td>
                            <td><span class="output" id="payment"></span></td></tr>
                            <tr><td>Ընդհանուր մուծում:</td>
                            <td><span class="output" id="total"></span></td></tr>
                            <tr><td>Տոկոսի գումարը:</td>
                            <td><span class="output" id="totalinterest"></span></td></tr>
                        </table>
                        <canvas id="graph" height="250"></canvas>
                        </div>
                   
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={this.handleClose}>
                            Փակել
                        </Button>
            
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    mailresponse: state.mailReducer.mailresponse,
    newsData: state.newsReducer.newsData,
})

const actionCreators = {
    mail: mailAction,
    news: newsAction,
};

const connectedHome = connect(mapStateToProps, actionCreators)(Home);
export {connectedHome as Home};
