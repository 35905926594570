import React, {Component} from "react";
// import {connect} from 'react-redux';
// import {resetNumberAction} from '../../_actions';
import {Steps} from './../../_components/steps';
import Translate from "react-translate-component";



class ResetPassword extends Component {
    state = {
        user: {
            phoneNumber: '',
            verificationNumber: '',
            password: '',
            rePassword: ''
        },
        errors: {
            phoneNumberError: '',
            passwordError: '',
            rePasswordError: '',
        }
    };

    setInput = (e) => {
        // const {name, value} = e.target;
        // const {user} = this.state;
        // const error = Validation.validateInputs(name,value,user.password)
        // this.setState({
        //     errors: {
        //         ...this.state.errors,
        //         [`${name}Error`]: error
        //     },
        //     user: {
        //         ...user,
        //         [name]: value
        //     }
        // });
    }


    resetPass = (e) => {
        // const {resetStep = 0} = this.props;
        // e.preventDefault();
        // const {user,errors} = this.state;
        // if(!errors.phoneNumberError && resetStep===0 && user.phoneNumber){
        //     this.props.resetNumber(user,resetStep)
        // }else if(resetStep===1){
        //     this.props.resetNumber(user,resetStep)
        // }else if(!errors.phoneNumberError &&
        //          !errors.passwordError &&
        //          !errors.rePasswordError &&
        //          user.phoneNumber &&
        //          user.password &&
        //          user.rePassword && resetStep===2){
        //     this.props.resetNumber(user,resetStep)
        // }

    }
    render() {
        const {
            user:{phoneNumber, verificationNumber,password,rePassword},
            errors:{phoneNumberError,passwordError,rePasswordError},
              } = this.state;
        const {resetStep = 0,resetError} = this.props;
        return (
            <div className="mainContent">
                <div className="container my-5">
                <div className="row my-4">
                    <div className="col-12">
                        <Steps step={resetStep} />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-6 mb-5">
                        <div className=" m-2  shadow p-3 bg-white rounded RegisterContent">
                            <h1 className="text-info text-center display-4 RegisterContent--title"><Translate content="resetPassword.title" /></h1>
                            <form onSubmit={this.resetPass}>
                                {
                                    resetStep === 0 ?
                                        (
                                            <div className='form-group'>
                                            {this.props.error}
                                                <label><Translate content="register.phone" /></label>
                                                <div className="RegisterContent--phone">
                                                    <span className="RegisterContent--phone--prefix">+374</span>
                                                    <input type='number'
                                                           className="RegisterContent--phone--input "
                                                           name='phoneNumber'
                                                           onChange={this.setInput}
                                                           placeholder="00 000000"
                                                           value={phoneNumber}/>
                                                </div>
                                            {phoneNumberError ? (
                                                <span className="badge badge-danger RegisterContent--errorsPopover">{phoneNumberError}</span>
                                            ):null}
                                            </div>
                                        ) : resetStep === 1 ?
                                        (
                                            <div className='form-group'>
                                                <label><Translate content="register.verification number" /></label>
                                                <div className="RegisterContent--phone">
                                                    <input type='number' className="RegisterContent--phone--input"
                                                           name='verificationNumber' onChange={this.setInput}
                                                           value={verificationNumber}/>
                                                </div>
                                            </div>
                                        ) : resetStep === 2 ? (
                                            <div>
                                                <div className='form-group'>
                                                    <label><Translate content="register.password" /></label>
                                                    <div className="RegisterContent--phone">
                                                        <input type='password'
                                                               className="form-control"
                                                               name='password'
                                                               onChange={this.setInput}
                                                               value={password}/>
                                                    </div>
                                                    {passwordError ? (
                                                        <span className="badge badge-danger RegisterContent--errorsPopover">
                                                            {passwordError}
                                                        </span>
                                                    ):null}
                                                </div>
                                                <div className='form-group'>
                                                    <label><Translate content="register.password verification" /></label>
                                                    <div className="RegisterContent--phone">
                                                        <input type='password'
                                                               className="form-control"
                                                               name='rePassword'
                                                               onChange={this.setInput}
                                                               value={rePassword}/>
                                                    </div>
                                                    {rePasswordError ? (
                                                        <span className="badge badge-danger RegisterContent--errorsPopover">
                                                            {rePasswordError}
                                                        </span>
                                                    ):null}
                                                </div>
                                            </div>

                                        ): resetStep === 3 ? (
                                        <div className="my-3">
                                        </div>
                                    ): null
                                }

                                {
                                    resetStep !== 3?(
                                        <div className='form-group'>
                                            <button type="submit"
                                                    className="btn btn-info w-100 text-center">
                                                {resetStep === 0 ? <Translate content="register.Send Message" /> :
                                                 resetStep === 1 ? <Translate content="register.Send Code" /> :
                                                 resetStep === 2 ? <Translate content="resetPassword.title" /> :
                                                 <Translate content="reset.title" />}
                                            </button>
                                        </div>
                                    ):null
                                }

                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>

        )
    }
}

// function mapStateToProps(state) {
//     const {resetStep,resetError} = state.userReducer;
//     return {resetStep,resetError};
// }
//
// const actionCreators = {
//     resetNumber: resetNumberAction,
// };
//
// const connectedResetPassword = connect(mapStateToProps, actionCreators)(ResetPassword);
// export {connectedResetPassword as ResetPassword};

export default ResetPassword
