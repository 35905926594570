import React, { Component } from "react";
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import Translate from "react-translate-component";
import './news.scss';
import {BASE_URL} from "../../_config";
import {BsCalendar} from 'react-icons/bs';
import { newsAction } from './../../_actions';


class News extends Component {

    componentDidMount() {
        this.props.news("another")
    }
    render() {
        const { newsData } = this.props
        return (
            <div className="productContent">
                <section className="container ">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="title_1 mt-5"><Translate content="news.title" /></h1>
                        </div>
                    </div>
                    <div className="row my-5">
                            {newsData && newsData.length ? newsData.map(news => (
                                <div className="col-md-6">
                                    <div className="card mb-3 p-0 " >
                                        <div className="row no-gutters">
                                            <div className="col-md-4">
                                                <img src={`${BASE_URL}${news.image}`} className="card-img h-100" alt="..." />
                                            </div>
                                            <div className="col-md-8">
                                                <div className="card-body">
                                                    <h5 className="card-title">{news.title}</h5>
                                                    <p className="card-text">{news.description}</p>
                                                    <p className="card-text"><BsCalendar className="mr-2"/><small className="text-muted">{news.created_at}</small></p>
                                                    <Link to={`news/details/${news.id}`} type="button" className="btn btn-secondary"><Translate content="news.more"/></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )):""}

                    </div>
                    {/*<div className="row mb-5">*/}
                    {/*    <div className="col-md-12">*/}
                    {/*        <nav aria-label="Page navigation">*/}
                    {/*            <ul className="pagination paginList">*/}
                    {/*                <li className="page-item page-link"><Translate content="pagination.Previous" /></li>*/}
                    {/*                <li className="page-item page-link">1</li>*/}
                    {/*                <li className="page-item page-link">2</li>*/}
                    {/*                <li className="page-item page-link">3</li>*/}
                    {/*                <li className="page-item page-link"><Translate content="pagination.next" /></li>*/}
                    {/*            </ul>*/}
                    {/*        </nav>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
     newsData : state.newsReducer.newsData,
})

const actionCreators = {
    news: newsAction,
};

const connectedNews = connect(mapStateToProps, actionCreators)(News);
export { connectedNews as News };
