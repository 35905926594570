import axios from "axios";
import {BASE_API_URL} from "../../_config";

export  function selectNews(type) {
    return axios({
        method : 'get',
        url : `${BASE_API_URL}news?type=${type}`,
        headers:{},
    })
}


export  function singleNews(id) {
    return axios({
        method : 'get',
        url : `${BASE_API_URL}news/${id}`,
        headers:{},
    })
}
