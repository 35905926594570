import React, { Component } from "react";
import { NavLink,Link } from 'react-router-dom';
import { FaRegUser } from 'react-icons/fa';
import  {Navbar} from '../../../_components'
import {AiOutlineMenu } from 'react-icons/ai';
import Translate from "react-translate-component";
import Logo from "./../../../_assets/img/logo.png"
import {NavDropdown} from "react-bootstrap";



import './header.scss';
import counterpart from "counterpart";

export class Header extends Component {
    state = {
        showNavbar: false,
    };
    handleNavbar = () => {
        this.setState({showNavbar: true})
    }
    hideNavbar = () => {
        this.setState(state => {
            return { showNavbar: false, }
        })
    }
    render() {
        const {showNavbar} = this.state
        return (
            <>
            <section className="header">
                <div className="container">
                {/* <div class="row header--Content py-3"><div class="col-12 info-text"></div></div> */}
                    <div className="row header--Content py-3">
                        <div className="col-lg-2 col-md-3 col-sm-6 col-7">
                            <Link to={"/"}>
                                <img className="img-fluid logo" src={Logo} alt="logo"/>
                            </Link>
                        </div>
                        <nav className="col-md-7 header--Content--navbar">
                            <ul className="header--Content--navbar--list">
                                <li className="header--Content--navbar--list--item "><NavLink exact to={'/'} activeClassName="active"><Translate content="menu.home" /></NavLink></li>
                                <li className="header--Content--navbar--list--item "><NavLink exact to={'/about'} activeClassName="active"><Translate content="menu.about" /></NavLink></li>
                                <li className="header--Content--navbar--list--item ">
                                    <NavDropdown title={counterpart.translate("banks.title")} id="basic-nav-dropdown">
                                    <NavDropdown.Item href="/banks/news"><Translate content="banks.news" /></NavDropdown.Item>
                                    <NavDropdown.Item href="/banks/information"><Translate content="banks.info" /></NavDropdown.Item>
                                </NavDropdown>
                                </li>
                                <li className="header--Content--navbar--list--item"><NavLink exact to={'/news'} activeClassName="active"><Translate content="menu.news" /></NavLink></li>
                                {/*<li className="header--Content--navbar--list--item"><NavLink exact to={'/contact'} activeClassName="active"><Translate content="menu.contact us" /></NavLink></li>*/}
                            </ul>
                        </nav>
                        <div className="col-md-2 col-sm-6 col-5 header--Content--cart">
                            <ul className="header--Content--cart--list">
                                <li className="header--Content--cart--list--item menu" onClick={this.handleNavbar}><AiOutlineMenu /></li>
                                {/*<li className="header--Content--cart--list--item"><FaRegUser /></li>*/}
                            </ul>

                        </div>
                    </div>
                </div>
            </section>
        {showNavbar? <Navbar closeNavbar={this.hideNavbar}/>:""}
        </>
        )
    }
}
