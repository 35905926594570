import axios from "axios";
import {BASE_API_URL} from "../../_config";

export  function sendMAil(body) {
    return axios({
        method : 'post',
        url : `${BASE_API_URL}banking/request`,
        data:body,
        headers:{},
    })
}

