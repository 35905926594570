import React from 'react';
import Translate from "react-translate-component";
import { IoMdClose } from 'react-icons/io';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { NavLink, } from 'react-router-dom';
import './navbar.scss';
import { FaRoad } from 'react-icons/fa';
import {NavDropdown} from "react-bootstrap";
import counterpart from "counterpart";

export const Navbar = ({closeNavbar}) => {
    const paerentElementClick =(e) =>{
        if(e.target.className==="navbarContent") {
            closeNavbar()
        }
    }
    return (
        <div className="navbarContent" onClick={(e)=>paerentElementClick(e)}>
            <div className="navbarContent--infoBox">
                <div className="navbarContent--infoBox--navigate">
                    <div className="navbarContent--infoBox--navigate--title"> </div>
                    <div className="navbarContent--infoBox--navigate--close" onClick={closeNavbar }><IoMdClose /></div>
                </div>
                <nav className="navbarContent--infoBox--list">
                    <ul className="navbarContent--infoBox--list--menu">
                        <li className="navbarContent--infoBox--list--menu--item">
                            <NavLink exact to={"/"} activeClassName="active"  onClick={closeNavbar}>
                                <Translate content="menu.home" /> <span><MdKeyboardArrowRight /></span>
                            </NavLink >
                        </li>
                        <li className="navbarContent--infoBox--list--menu--item ">
                            <NavDropdown title={counterpart.translate("banks.title")} id="basic-nav-dropdown">
                                <NavDropdown.Item href="/banks/news"><Translate content="banks.news" /></NavDropdown.Item>
                                <NavDropdown.Item href="/banks/information"><Translate content="banks.info" /></NavDropdown.Item>
                            </NavDropdown>
                        </li>

                        <li className="navbarContent--infoBox--list--menu--item">
                            <NavLink exact to={"/news"} activeClassName="active"  onClick={closeNavbar}>
                                <Translate content="menu.news" /> <span><MdKeyboardArrowRight /></span>
                            </NavLink >
                        </li>
                        <li className="navbarContent--infoBox--list--menu--item">
                            <NavLink exact to={"/about"} activeClassName="active"  onClick={closeNavbar}>
                                <Translate content="menu.about" /> <span><MdKeyboardArrowRight /></span>
                            </NavLink >
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    )


}
