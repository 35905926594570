import React from "react";
import {Modal,Button} from "react-bootstrap";
import {FaCheckCircle} from 'react-icons/fa';
export  const VerticallyCenteredModal=(props)=> {


    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                   Շնորհակալություն
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className=" text-center">
                <div className="display-4  text-success">
                <FaCheckCircle />
                </div>
                <h4>Հարգելի հաճախորդ</h4>
                <p>
                    Ձեր հայտը ընդունված է:
                </p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className=" text-center">
                <Button variant="danger" onClick={props.onHide}>Փակել</Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
