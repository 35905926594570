import React, { Component } from "react";
import Translate from "react-translate-component";


export class About extends Component {
    render() {
        return (
            <div className="container">
                <div className="row" id="contatti">
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="title_1 my-3"><Translate content="about.title"/></h1>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <p>Bank24.am-ը ֆինանսական շուկայում տեղեկատվական հարթակ է։</p>
                                <p>
                                    Այստեղ հաճախորդները հնարավորություն ունեն օնլայն հարցման միջոցով ստանալ խորհրդատվություն իրենց հասանելի վարկատեսակների հետ կապված, կայքում ներկայացվում են օրվա փոխարժեքները, բանկերի թոփ առաջարկները
                                </p>
                                <p>
                                    Առաջարկում ենք գումարի տրամադրում առավելագույնը 1 ժամում կենցաղային տեխնիկայի առքուվաճառքի միջոցով։ Մասնագետները լավագույնս տիրապետում են ապառիկի տրամադրման բոլոր մոտեցումներին և պատրաստ են աջակցել արագ գումար ստանալուն։
                                    Կայքում տեղ գտած սույն ինֆորմացիան համեմատության համար է, որպեսզի հաճախորդը պատկերացում կազմի՝ տվյալ գործարքը՝ որպես վարկ ինչ է արժենում։
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
