import React, { Component } from "react";
import {BsCalendar} from 'react-icons/bs';
import './newsDetals.scss';
import {singleNewsAction} from "../../_actions/news";
import {connect} from "react-redux";
import {BASE_URL} from "../../_config";

class NewsDetals extends Component {

    componentDidMount() {
       const id = this.props.match.params.id;
        this.props.news(id)
    }
    componentDidUpdate(prevProps, prevState) {
        const id = this.props.match.params.id;
        if(id !==prevProps.match.params.id){
            this.props.news(id)
        }
    }

    render() {
        const {singleNews}= this.props
        console.log(singleNews)


        return (
            <div className="newsContent">
                <section className="container slideContent my-5">
                    {singleNews ?
                        <div className="row">
                            <div className="col-md-12">
                                <img src={`${BASE_URL}${singleNews.image}`} className="card-img h-100" alt="..." />
                            </div>
                            <div className="col-md-12">
                                <p className="card-text"><BsCalendar className="mr-2"/><small className="text-muted">{singleNews.created_at}</small></p>
                                <h1>{singleNews.title}</h1>
                                {singleNews.description}
                            </div>
                        </div>
                        :""
                    }


                </section>
            </div>
        )
    }
}

const mapStateToProps =(state) => ({
    singleNews : state.newsReducer.singleNews,
})

const actionCreators = {
    news: singleNewsAction,
};

const connectedNewsDetals = connect(mapStateToProps, actionCreators)(NewsDetals);
export { connectedNewsDetals as NewsDetals };
