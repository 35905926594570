import { newsConstants } from './../../_constants';

export const  newsReducer = (state = {}, action) => {
    switch (action.type) {
        case newsConstants.NEWS_REQUEST:
            return { newsData:[] };
        case newsConstants.NEWS_SUCCESS:
            return { ...state, newsData: action.data.data};
        case newsConstants.NEWS_FAILURE:
            return { ...state,newsError: action.error };

        case newsConstants.SINGLE_NEWS_REQUEST:
            return { singleNews:[] };
        case newsConstants.SINGLE_NEWS_SUCCESS:
            return { ...state, singleNews: action.data.data};
        case newsConstants.SINGLE_NEWS_FAILURE:
            return { ...state,newsError: action.error };
         default:
                return state
    }
}




