import React, { Component } from "react";

import './contact.scss';

export class Contact extends Component {
    render() {
        return (
            <div className="container">
                <div className="row" id="contatti">
                    <div className="container mt-5">

                        <div className="row">
                            <div className="col-md-6 maps">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11880.492291371422!2d12.4922309!3d41.8902102!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x28f1c82e908503c4!2sColosseo!5e0!3m2!1sit!2sit!4v1524815927977"
                                    frameBorder="0" style={{border:"0"}} allowFullScreen></iframe>
                            </div>
                            <div className="col-md-6">
                                <h2 className="text-uppercase mt-3 font-weight-bold text-white">CONTATTACI</h2>
                                <form action="">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control mt-2"
                                                       placeholder="Nome/Società" required />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control mt-2" placeholder="Oggetto"
                                                       required />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input type="email" className="form-control mt-2" placeholder="Email"
                                                       required />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input type="number" className="form-control mt-2"
                                                       placeholder="Telefono" required />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <textarea className="form-control" id="exampleFormControlTextarea1"
                                                          placeholder="Inserisci testo" rows="3" required></textarea>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value=""
                                                           id="invalidCheck2" required />
                                                        <label className="form-check-label" htmlFor="invalidCheck2">
                                                            Accetta le condizioni
                                                        </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button className="btn btn-light" type="submit">Invia</button>
                                        </div>
                                    </div>
                                </form>
                                <div className="text-white">
                                    <h2 className="text-uppercase mt-4 font-weight-bold">dove siamo</h2>

                                    <i className="fas fa-phone mt-3"></i>(+39) 123456<br/>
                                    <i className="fas fa-phone mt-3"></i> (+39) 123456<br/>
                                    <i className="fa fa-envelope mt-3"></i> info@test.it<br/>
                                    <i className="fas fa-globe mt-3"></i> Piazza del Colosseo, 1, 00184 Roma<br/>
                                    <i className="fas fa-globe mt-3"></i> Piazza del Colosseo, 1, 00184 Roma<br/>
                                    <div className="my-4">
                                        <i className="fab fa-facebook fa-3x pr-4"></i>
                                        <i className="fab fa-linkedin fa-3x"></i>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
