import React, {Component} from "react";
// import {connect} from 'react-redux';
// import {phoneNumberAction} from '../../_actions';
import "./register.scss";
import Translate from "react-translate-component";
import counterpart from "counterpart";



class Register extends Component {
    state = {
        user: {
            phoneNumber: '',
            verificationNumber: '',
            firstName: '',
            lastName: '',
            password: '',
            rePassword: ''
        },
        errors: {
            phoneNumberError: '',
            firstNameError: '',
            lastNameError: '',
            passwordError: '',
            rePasswordError: '',
        }
    };

    setInput = (e) => {
        // const {name, value} = e.target;
        // const {user} = this.state;
        // const error = Validation.validateInputs(name,value,user.password)
        // this.setState({
        //     errors: {
        //         ...this.state.errors,
        //         [`${name}Error`]: error
        //     },
        //     user: {
        //         ...user,
        //         [name]: value
        //     }
        // });
    }


    registerNumber = (e) => {
        // const {step = 0} = this.props;
        // e.preventDefault();
        // const {user,errors} = this.state;
        // if(!errors.phoneNumberError && step===0 && user.phoneNumber){
        //     this.props.registerNumber(user,step)
        // }else if(step===1){
        //     this.props.registerNumber(user,step)
        // }else if(!errors.phoneNumberError &&
        //          !errors.firstNameError &&
        //          !errors.lastNameError &&
        //          !errors.passwordError &&
        //          !errors.rePasswordError &&
        //          user.phoneNumber &&
        //          user.firstName &&
        //          user.lastName &&
        //          user.password &&
        //          user.rePassword && step===2){
        //     this.props.registerNumber(user,step)
        // }
       
    }
    render() {
        const {user:{firstName,lastName,password,rePassword}} = this.state;
        return (
            <div className="mainContent">
                <div className="container my-5">
                <div className="row my-4">
                    <div className="col-12">

                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-6 mb-5">
                        <div className=" m-2  shadow p-3 bg-white rounded RegisterContent">
                            <h1 className="mainText text-center display-4"><Translate content="user.register" /></h1>
                            <form onSubmit={this.registerNumber}>

                                            <div>
                                                <div className='form-group'>
                                                    <label><Translate content="user.email" /></label>
                                                    <div className="RegisterContent--phone">
                                                        <input type='text' className="form-control"
                                                               name='firstName' onChange={this.setInput}
                                                               value={firstName}/>
                                                    </div>
                                                </div>
                                                <div className='form-group'>
                                                    <label><Translate content="user.firstName" /></label>
                                                    <div className="RegisterContent--phone">
                                                        <input type='text' className="form-control"
                                                               name='firstName' onChange={this.setInput}
                                                               value={firstName}/>
                                                    </div>
                                                </div>
                                                <div className='form-group'>
                                                    <label><Translate content="user.lastName" /></label>
                                                    <div className="RegisterContent--phone">
                                                        <input type='text' className="form-control"
                                                               name='lastName' onChange={this.setInput}
                                                               value={lastName}/>
                                                    </div>
                                                </div>
                                                <div className='form-group'>
                                                    <label><Translate content="user.password" /></label>
                                                    <div className="RegisterContent--phone">
                                                        <input type='password'
                                                               className="form-control"
                                                               name='password'
                                                               onChange={this.setInput}
                                                               value={password}/>
                                                    </div>
                                                </div>
                                                <div className='form-group'>
                                                    <label><Translate content="user.rePassword" /></label>
                                                    <div className="RegisterContent--phone">
                                                        <input type='password'
                                                               className="form-control"
                                                               name='rePassword'
                                                               onChange={this.setInput}
                                                               value={rePassword}/>
                                                    </div>
                                                </div>
                                            </div>




                                        <div className='form-group'>
                                            <button type="submit" className="btn mainBtn w-100 text-center">
                                                 <Translate content="user.register" />
                                            </button>
                                        </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            
        )
    }
}

// function mapStateToProps(state) {
//     const {step,registreError} = state.userReducer;
//     return {step,registreError};
// }
//
// const actionCreators = {
//     registerNumber: phoneNumberAction,
// };
//
// const connectedRegisterPage = connect(mapStateToProps, actionCreators)(Register);
// export {connectedRegisterPage as Register};
export default Register;
