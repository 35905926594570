import { mailConstants } from './../../_constants';

export const  mailReducer = (state = {}, action) => {
    switch (action.type) {
        case mailConstants.MAIL_REQUEST:
            return {  };
        case mailConstants.MAIL_SUCCESS:
            return { ...state, mailresponse: action.data.data};
        case mailConstants.MAIL_FAILURE:
            return { ...state,newsError: action.error };
        default:
            return state
    }
}




