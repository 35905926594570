import { combineReducers } from 'redux';

import {newsReducer} from './news';
import {mailReducer} from './mail';

const rootReducer = combineReducers({
    newsReducer,
    mailReducer,
});

export default rootReducer;
